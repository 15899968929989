import { inject, Injectable } from '@angular/core';
import { FollowedListing, Listing } from '../models/listing.interface';
import { ListingImage } from '../models/images.interface';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { UserService } from '../../../shared/services/user/user.service';
import { LocationService } from './location.service';
import { ListingLocation } from '../models/location.interface';
import { of } from 'rxjs';
import { FirebaseImageService } from './firebase-image.service';

@Injectable({
  providedIn: 'root',
})
export class ListingsService {
  protected http = inject(HttpClient);
  protected userService = inject(UserService);
  protected firebaseImageService = inject(FirebaseImageService);

  public isListingOwner(userId: number) {
    if (!userId) {
      return false;
    }
    const userData = this.userService.getUserData();
    if (!userData) {
      return false;
    }
    return userData.id === userId;
  }

  // public createListing(data: ListingCreate) {
  public createListing(data: any) {
    return this.http.post('/advertisement/create', data, {
      observe: 'response',
    });
  }

  public updateListing(data: any) {
    return this.http.put('/advertisement/update', data, {
      observe: 'response',
    });
  }

  public updateListingStatus(listingId: number, isActive: boolean) {
    return this.http.put("/advertisement/updateStatus", {
      id: listingId,
      isActive: isActive
    });
  }

  public getListingsByUserId(userId: number) {
    const queryParams = new HttpParams()
    .set('AppUserId', userId);

    return this.http.get('/advertisement/listByAppUser', {
      params: queryParams,
      observe: 'response',
    });
  }

  public getListingsByUserJwt() {
    return this.http.get('/advertisement/listByJwt', {
      observe: 'response',
    });
  }

  public getListingById(listingId: number) {
    // return this.getNewestListings().find(listing => listingId === listing.id);
    const queryParams =
      new HttpParams()
        .set('Id', listingId);

    return this.http.get('/advertisement/details', {
      params: queryParams,
      observe: 'response',
    });
  }

  public getNewestListings(count: number = 10) {
    const queryParams = new HttpParams()
      .set("Count", count);

    return this.http.get("/advertisement/getLatest", {
      params: queryParams,
      observe: "response"
    });
  }

  public deleteListing(listingId: number) {
    const queryParams = new HttpParams()
      .set("Id", listingId);

    return this.http.delete("/advertisement/delete", {
      params: queryParams
    });
  }

  public getSearchedListings(): Listing[] {
    return this.generateRandomListings();
  }

  public getSearch(data: any) {
    return this.http.post("/advertisement/search", {
      filters: data
    })
  }

  public getClosestListings(location: any, radius: number = 5, count: number = 10) {
    if (!location) {
      return of(
        new HttpResponse({
          status: 200,
          body: { code: 200, data: { advertisements: [] } },
        })
      );
    }

    const queryParams = new HttpParams()
      .set("Latitude", location.latitude)
      .set("Longitude", location.longitude)
      .set("Radius", radius)
      .set("Count", count);

    return this.http.get("/advertisement/getNearest", {
      params: queryParams,
      observe: "response"
    });
  }

  public getFollowedListings() {
    return this.http.get("/advertisementWatchlist/listByJwt", {
      observe: "response"
    });
  }

  public saveFollowedListing(listingId: number) {
    return this.http.post("/advertisementWatchlist/add", {
      advertisementId: listingId
    },{
      observe: "response"
    });
  }

  public deleteFollowedListing(listingId: number) {
    const queryParams = new HttpParams()
      .set("AdvertisementId", listingId);

    return this.http.delete("/advertisementWatchlist/delete", {
      params: queryParams,
      observe: "response"
    });
  }

  public saveFollowedListingToLocalStorage(listingId: number) {
    const followedListings  = localStorage.getItem("followed-listings");
    const followedListingsParsed: FollowedListing[] = followedListings ?  JSON.parse(followedListings) : [];

    if (!followedListingsParsed) {
      localStorage.setItem("followed-listings", JSON.stringify([{
        listingId
      }]));
      return;
    }

    const isFollowed = followedListingsParsed.some(item => item.listingId === listingId);

    if (!isFollowed) {
      const temp = [...followedListingsParsed, {listingId}]
      localStorage.setItem("followed-listings", JSON.stringify(temp));
    }
  }

  public saveFollowedListingsToLocalStorage() {
    this.getFollowedListings().subscribe((res: any) => {
      if (res.status !== 200 || res.body.code !== 200) {
        localStorage.removeItem("followed-listings");
        return;
      }

      const listings = res.body.data.advertisements.map((item: any) => ({listingId: item.id}));

      localStorage.setItem("followed-listings", JSON.stringify(listings));
    })
  }

  public deleteFollowedListingFromLocalStorage(listingId: number) {
    const followedListings  = localStorage.getItem("followed-listings");
    const followedListingsParsed: FollowedListing[] = followedListings ? JSON.parse(followedListings) : [];

    if (!followedListingsParsed) {
      return;
    }

    const temp = followedListingsParsed.filter(item => item.listingId !== listingId);
    localStorage.setItem("followed-listings", JSON.stringify(temp));
  }

  public isListingFollowed(listingId: number): boolean {
    const followedListings  = localStorage.getItem("followed-listings");
    const followedListingsParsed: FollowedListing[] = followedListings ?  JSON.parse(followedListings) : [];

    return followedListingsParsed.some(item => item.listingId === listingId);
  }

  protected generateRandomListings(count: number = 20): Listing[] {
    // return this.getNewestListings();
    const listings: Listing[] = [];
    const images: ListingImage[] = [];
    for (let i = 0; i < 4; i++) {
      const image = {
        imageSrc: `https://primefaces.org/cdn/primeng/images/galleria/galleria${10 + i}.jpg`,
        thumbnailImageSrc: `https://primefaces.org/cdn/primeng/images/galleria/galleria${10 + i}.jpg`,
        alt: 'Description for Image 1',
      };
      images.push(image);
    }
    for (let i = 0; i < count; i++) {
      const listing = {
        id: 1001 + i,
        userId: 2,
        dateCreated: '2024-09-01 15:00',
        dateUpdated: '2024-09-01 20:00',
        isActive: true,
        placeType: 'Room',
        offerType: 'Na wynajem',
        squareFootage: 50,
        totalPrice: 5500,
        price: 3400 + i,
        avaliableFrom: '2024-09-10',
        location: {
          description: 'Nawojowska',
          latitude: 123,
          longitude: 123,
          radius: 0,
        },
        rent: null,
        additionalCosts: null,
        deposit: null,
        numberOfRooms: null,
        heatingType: null,
        typeOfBuilding: null,
        market: 'Pierwotny',
        floor: null,
        numberOfFloors: null,
        cookerType: null,
        ovenType: null,
        animalsAcceptance: null,
        furnishing: null,
        houseHoldAppliances: null,
        additional: null,
        images: images,
      };
      listings.push(listing);
    }
    return listings;

  }
}
