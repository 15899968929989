import { inject, Injectable, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Breadcrumb } from '../../types/breadcrumb.interface';

// TODO: Fix breadcrumbs are not being updated when there are query params in the string
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public breadcrumbs = signal<Breadcrumb[]>([]);
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.breadcrumbs.set(this.createBreadcrumbs(this.activatedRoute.root));
    });
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const breadcrumbLabel = child.snapshot.data['breadcrumb'];
      if ((!breadcrumbs.length || breadcrumbs[breadcrumbs.length - 1].label !== breadcrumbLabel) && breadcrumbLabel) {
        breadcrumbs.push({ label: breadcrumbLabel, url: url });
      }

      this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}

