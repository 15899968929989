import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavComponent } from '../partials/nav/nav.component';
import { FooterComponent } from '../partials/footer/footer.component';
import { SidebarComponent } from '../partials/sidebar/sidebar.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'hh-main-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NavComponent,
    FooterComponent,
    SidebarComponent,
    ScrollPanelModule,
    BreadcrumbComponent,
    ToastModule,
  ],
  template: `
    <div class="min-h-screen flex flex-col pb-[5rem]">
      <hh-nav
        [active]="sidebarActive"
        (activeChangeEvent)="changeVisibility()" />
      <div class="pt-[75px]">
        <hh-sidebar
          [active]="sidebarActive"
          (activeChangeEvent)="changeVisibility()" />
        <!--        <main class='flex-1 pt-6 px-6 lg:px-32 overflow-y-auto max-h-[calc(100vh-64px)]'>-->
<!--        <main class="flex-1 pt-6 px-6 lg:px-32">-->
<!--        TODO: Fix this margin auto, in navbar also-->
        <main class="flex-1 pt-6 xl:max-w-[1200px] mx-8 xl:mx-auto">
          <hh-breadcrumb />
          <!--          <ng-content></ng-content>-->
          <router-outlet></router-outlet>
        </main>
      </div>
    </div>
  `,
})
export class MainLayoutComponent {
  sidebarActive: boolean = false;

  changeVisibility() {
    this.sidebarActive = !this.sidebarActive;
  }
}
