import { Component, effect, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { ProfileDropdownComponent } from './components/profile-dropdown.component';
import { Router, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { NgIf } from '@angular/common';
import { UserService } from '../../../shared/services/user/user.service';

@Component({
  selector: 'hh-nav',
  standalone: true,
  imports: [ProfileDropdownComponent, RouterLink, ButtonModule, MenubarModule, NgIf],
  template: `
    <nav
      class="fixed bg-white dark:bg-[#121212] z-[10] w-full h-[75px] border-b border-1 border-gray-300 py-4"
    >
      <div class="xl:max-w-[1200px] mx-8 xl:mx-auto flex items-center">
        <a routerLink="/" class="mr-auto">House Hunt - dev</a>
        <p-button class="lg:hidden" (click)="activeChange()" icon="pi pi-bars" />
        <div class="hidden lg:flex gap-4">
          <p-menubar [model]="items" styleClass="border-none" />
        </div>
        <div class="hidden lg:block lg:ml-auto">
          <hh-nav-profile-dropdown></hh-nav-profile-dropdown>
        </div>
      </div>
    </nav>
  `,
})
export class NavComponent implements OnInit {
  protected router = inject(Router);
  protected userService: UserService = inject(UserService);

  @Input() active: boolean = false;
  @Output() activeChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>;

  items: MenuItem[] | undefined;

  activeChange() {
    this.activeChangeEvent.emit();
  }

  constructor() {
    effect(() => {
      this.items = [
        {
          label: 'Ogłoszenia',
          icon: 'pi pi-clipboard',
          items: [
            {
              label: 'Wyszukaj',
              route: '/listings/search',
              command: () => {
                this.router.navigate(['/listings/search'], { replaceUrl: true });
              },
            },
            {
              label: 'Stwórz',
              route: '/listings/create',
              visible: this.userService.isLogged(),
              command: () => {
                this.router.navigate(['/listings/create']);
              },
            },
            {
              label: 'Obserwowane',
              visible: this.userService.isLogged(),
              command: () => {
                this.router.navigate(['/user/profile'], { replaceUrl: true, fragment: "listings-followed" });
              },
            },
          ],
        },
      ];
    });
  }

  ngOnInit() {

  }
}
