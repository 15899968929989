import { Component, effect, inject, OnInit } from '@angular/core';
import { RouterLink } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { MenuModule } from "primeng/menu";
import { MenuItem } from 'primeng/api';
import { UserService } from '../../../../shared/services/user/user.service';
import { AuthService } from '../../../../features/auth/services/auth/auth.service';
import { Ripple } from 'primeng/ripple';

@Component({
  selector: "hh-nav-profile-dropdown",
  standalone: true,
  imports: [RouterLink, ButtonModule, MenuModule, Ripple],
  template: `
    <div class="flex justify-center">
<!--      TODO: add some avatar maybe-->
<!--      TODO: Move content of a dropdown to separate component or solve this differently-->
<!--      <p-button (click)="menu.toggle($event)" icon="pi pi-user" />-->
<!--      @if(userService.isLogged()) {-->
<!--        <p-button (click)="menu.toggle($event)" label="DH" />-->
<!--      } @else {-->
<!--      }-->
      <p-button (click)="menu.toggle($event)" icon="pi pi-user"/>
      <p-menu baseZIndex="9999" #menu [model]="menuItems" [popup]="true" appendTo="body">
        <ng-template pTemplate="item" let-item>
<!--          @if(!item.authRequired || (item.authRequired && userService.isLogged())){-->
          @if(item.visible) {
            <a [routerLink]="item.route" class="p-menu-item-link">
              <span [class]="item.icon"></span>
              <span class="ml-2">{{ item.label }}</span>
            </a>
          }
<!--          }-->
        </ng-template>
      </p-menu>
    </div>
  `,
})
export class ProfileDropdownComponent implements OnInit {
  protected authService = inject(AuthService);
  protected userService: UserService = inject(UserService);
  protected menuItems: MenuItem[] | undefined;

  constructor() {
    effect(() => {
      this.menuItems = [
        {
          label: "Twoje konto",
          items: [
            {
              label: "Profil - AA",
              icon: "pi pi-user",
              route: "/user/profile",
              visible: this.userService.isLogged(),
            },
            {
              label: "Wyloguj się - AA",
              icon: "pi pi-power-off",
              visible: this.userService.isLogged(),
              command: () => {
                this.authService.logout();
              }
            },
            {
              label: "Logowanie",
              icon: "pi pi-sign-in",
              visible: !this.userService.isLogged(),
              route: "/auth/login",
            },
          ],
        },
      ];
    });
  }

  ngOnInit() {
  }

}
