import { Component, effect, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgClass } from '@angular/common';
import { ProfileDropdownComponent } from '../../nav/components/profile-dropdown.component';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { UserService } from '../../../../shared/services/user/user.service';
import { AuthService } from '../../../../features/auth/services/auth/auth.service';

@Component({
  selector: "hh-sidebar-content",
  standalone: true,
  imports: [RouterOutlet, RouterLink, NgClass, ProfileDropdownComponent, AccordionComponent, PanelMenuModule],
  template: `
    <div class="py-4 px-6 flex flex-col gap-4">
<!--      <hh-accordion>-->
<!--      </hh-accordion>-->
      <p-panelMenu [model]="items" styleClass="border-none" />
<!--      @for (item of sidebarItems; track item.label) {-->
<!--        <a-->
<!--          [routerLink]="item.route"-->
<!--          [ngClass]="['py-1 rounded-lg hover:bg-gray-100', item.active ? 'bg-gray-100' : '']">-->
<!--          <span class="px-4 font-medium">-->
<!--            {{ item.label }}-->
<!--          </span>-->
<!--        </a>-->
<!--      }-->
    </div>
  `,
})
export class SidebarContentComponent implements OnInit {
  protected items: any = [];
  protected router = inject(Router);
  protected userService = inject(UserService);
  protected authService = inject(AuthService);

  @Input() active!: boolean;
  @Output() activeChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>;

  activeChange() {
    this.activeChangeEvent.emit();
  }

  protected sidebarItems = [
    { label: "Stwórz", route: "/listings/create", active: false },
    { label: "Ostatnio widziane", route: "/listings/closest", active: false },
  ];

  constructor() {
    effect(() => {
      this.items = [
        {
          label: "Twoje konto",
          icon: "pi pi-user",
          items: [
            {
              label: "Profil",
              visible: this.userService.isLogged(),
              command: () => {
                this.router.navigate(["/user/profile"], {replaceUrl: true})
                this.activeChange();
              }
            },
            {
              label: "Logowanie",
              visible: !this.userService.isLogged(),
              command: () => {
                this.router.navigate(["/auth/login"], {replaceUrl: true})
                this.activeChange();
              }
            },
            {
              label: "Wyloguj",
              visible: this.userService.isLogged(),
              command: () => {
                this.authService.logout();
                this.activeChange();
              }
            },
          ]
        },
        {
          label: "Ogłoszenia",
          icon: "pi pi-clipboard",
          items: [
            {
              label: "Stwórz",
              visible: this.userService.isLogged(),
              command: () => {
                this.router.navigate(["/listings/create"], {replaceUrl: true})
                this.activeChange();
              }
            },
            {
              label: "Obserwowane",
              visible: this.userService.isLogged(),
              command: () => {
                this.router.navigate(["/user/profile"])
              }
            },
            {
              label: "Wyszukaj",
              command: () => {
                this.router.navigate(["/listings/search"], {replaceUrl: true})
                this.activeChange();
              }
            },
          ]
        }
      ];
    });
  }

  ngOnInit() {


    this.setActiveItem();

    this.router.events.subscribe(() => {
      this.setActiveItem();
    });
  }

  protected setActiveItem() {
    const currentRoute = this.router.url;

    const matchingItem = this.sidebarItems.find(
      item => item.route === currentRoute
    );

    this.sidebarItems.forEach(item => item.active = false);

    if (matchingItem) {
      matchingItem.active = true;
      if (this.active) {
        this.activeChange();
      }
    }
  }
}
