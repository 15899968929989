import {Component} from '@angular/core';

@Component({
  selector: 'hh-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
}
