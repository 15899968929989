import { inject, Injectable } from '@angular/core';
import { deleteObject, getBlob, getDownloadURL, listAll, Storage, uploadBytesResumable } from '@angular/fire/storage';
import { ref } from '@angular/fire/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseImageService {
  private storage = inject(Storage);

  private hostingUrl = 'https://firebasestorage.googleapis.com/v0/b/househunt-b9a38.firebasestorage.app/o/';
  private basePath = 'AdvertisementImages';
  private validFileTypes = ['png', 'jpg', 'jpge', 'webp'];

  uploadFiles(listingId: number, files: any): Promise<void> {
    const uploadPromises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && this.isValidFileType(file)) {
        const path = `${this.basePath}/${listingId}/${i}.jpg`;
        const storageRef = ref(this.storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadPromises.push(
          uploadTask.then(() => {
          }),
        );
      }
    }

    return Promise.all(uploadPromises)
      .then(() => {
        console.log('All files uploaded successfully');
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        throw error;
      });
  }

  public getAllListingImages2(listingId: number) {
    const storageRef = ref(this.storage, `${this.basePath}/${listingId}`);

    return listAll(storageRef)
      .then((result: any) => {
        const imagePromises = result.items.map((itemRef: any) =>
          getDownloadURL(itemRef).then((url) => {
            if (this.isImage(itemRef.name)) {
              return url;
            }
            return null;
          })
        );

        return Promise.all(imagePromises).then((urls) =>
          urls.filter((url) => url !== null).sort()
        );
      })
      .catch((err) => {
        console.error('Error fetching listing images:', err);
        throw err;
      });
  }

  public getAllListingImages(listingId: number) {
    const storageRef = ref(this.storage, `${this.basePath}/${listingId}`);

    return new Observable((observer) => {
      listAll(storageRef).then((result: any) => {
          const imageUrls: string[] = [];
          const imagePromises = result.items.map((itemRef: any) => {
            return getDownloadURL(itemRef).then((url) => {
              if (this.isImage(itemRef.name)) {
                imageUrls.push(url);
              }
            });
          });

          Promise.all(imagePromises).then(() => {
            imageUrls.sort();
            observer.next(imageUrls);
            observer.complete();
          }).catch((err) => {
            observer.error(err);
          });

        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  public getAllListingImagesAsBlobs(listingId: number): Observable<Blob[]> {
    const storageRef = ref(this.storage, `${this.basePath}/${listingId}`);

    return new Observable((observer) => {
      listAll(storageRef)
        .then((result: any) => {
          const blobPromises = result.items.map((itemRef: any) => {
            return getBlob(itemRef).then((blob) => {
              // return blob;
              return new File([blob], `test${blob.size}.jpg`, { type: blob.type });
            });
          });

          Promise.all(blobPromises)
            .then((blobs) => {
              observer.next(blobs);
              observer.complete();
            })
            .catch((err) => {
              observer.error(err);
            });
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  deleteAllFiles(listingId: number) {
    const storageRef = ref(this.storage, `${this.basePath}/${listingId}`);

    return listAll(storageRef)
      .then((result) => {
        const deletePromises = result.items.map((itemRef) => deleteObject(itemRef));

        Promise.all(deletePromises)
          .then(() => {
            console.log('All files deleted successfully');
          })
          .catch((error) => {
            console.error('Error deleting some files:', error);
          });
      })
      .catch((error) => {
        console.error('Error listing files for deletion:', error);
      });
  }

  private isImage(fileName: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
    return imageExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  }

  private isValidFileType(file: any) {
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    return this.validFileTypes.includes(fileExtension);
  }
}
