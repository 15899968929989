// breadcrumb.component.ts
import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumbs/breadcrumbs.service';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'hh-breadcrumb',
  template: `
    <div class="pb-6 font-bold flex items-center">
      @for (breadcrumb of breadcrumbService.breadcrumbs(); let idx = $index; track idx; let last = $last) {
        @if (!last) {
          <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a> <i class="pi pi-angle-right"></i>
        } @else {
          {{ breadcrumb.label }}
        }
      }
    </div>
  `,
  imports: [
    RouterLink,
  ],
})
export class BreadcrumbComponent implements OnInit {
  protected breadcrumbService = inject(BreadcrumbService);

  ngOnInit(): void {
    // this.breadcrumbs = this.breadcrumbService.breadcrumbs;
  }
}
