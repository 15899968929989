import { Component, inject, input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'hh-accordion',
  standalone: true,
  imports: [
    NgClass,
  ],
  template: `
    <div [ngClass]="['transition-all', isOpened() ? 'max-h-[1000px] opacity-100' : 'max-h-0 overflow-hidden opacity-0']">
      <ng-content>
      </ng-content>
    </div>
  `
})
export class AccordionComponent {
  public isOpened = input<boolean>(false);
}
